import type { ForwardedRef } from 'react'
import { forwardRef } from 'react'
import type { NarrowText } from './types'
import { Text } from './text'

const Paragraph = forwardRef(
  (props: NarrowText, ref: ForwardedRef<HTMLParagraphElement>) => (
    <Text ref={ref} as="p" {...props} />
  ),
)

Paragraph.displayName = 'Heading'

export const P = Paragraph
